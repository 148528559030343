:host {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    color: white;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }
.theBigOne {
  width:90vw;
  height:80vh;
  background-color: black;
  color: white;
  padding: 1%; 
  top: -10px;
    background: url(assets/img/header4.png);
    background-size: cover;
    background-blend-mode: overlay;
}

  .spacer {
    flex: 1;
  }

  .toolbar {
    height: 6vh;
    display: flex;
    background-color: black;
    color: white;
    width: 100%;
  }
 
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height:25%;
    width: 100%;
    background-color: black;
    /* background: url(assets/img/header.png);
    background-size: cover;
    background-blend-mode: overlay; */

  }

  svg.material-icons {
    height: 24px;
    width: auto;
  }

  svg.material-icons:not(:last-child) {
    margin-right: 8px;
  }

  .card svg.material-icons path {
    fill: #888;
  }

  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1%;
    width: 100%;
    height: 100%;
    font-size: .5rem;
    background-color: black;
  }

  .card {
    width: 9%;
    max-width: 9%;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 20px;
    color: white;
    padding-left:2%;
  }

  .card2 {
    border-radius: 2px;
    border: 1px solid white;
    background-color: black;
    width: 100%;
    height: 15% !important;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 20px;
    color: white;
}

  .card3 {
  }

  .card3:hover {
    transform: scale(3,3 )  translateY(10%);
    transition-duration: 1s ;
  }


  .card-container .card:not(:last-child) {
    margin-right: 0;
  }

  .card.card-small {
    height: 16px;
    width: 100px;
    color: white;
  }

    .card.card-medium {
    height: 150px;
    width: 268px;
    background-color:black;
  }
  
  .selectedTab {
    transform: translateY(-3px);
  }
  
  .selectedTextColor {
    fill:orange;
  }
  .card-voyager {
    width: 60%;
    height: 60%;
    margin-left: 20%;
    background-color: green;
    background: url(assets\img\header4.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
   }

  .card-container .card:not(.highlight-card) {
    cursor: pointer;
  }

  /* .card-container .card:not(.highlight-card):hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 17px rgba(black, 0.35);
  }
 */
  .card-container .card:not(.highlight-card):hover .material-icons path {
    fill: rgb(105, 103, 103);
  }

  .card.highlight-card {
    background-color: black;
    color: white;
    font-weight: 600;
    border: none;
    width: auto;
    min-width: 30%;
    position: relative;
  }

  .card.card.highlight-card span {
    margin-left: 60px;
  }

  svg#rocket {
    width: 80px;
    position: absolute;
    left: -10px;
    top: -24px;
  }

  svg#rocket-smoke {
    height: 100vh;
    position: absolute;
    top: 10px;
    right: 180px;
    z-index: -10;
  }

  a,
  a:visited,
  a:hover {
    color: orange;
    text-decoration: none;
  }

  a:hover {
    color: orange;
  }

  a:link {
    text-decoration: underline;
  }

  .terminal {
    position: relative;
    width: 80%;
    max-width: 600px;
    border-radius: 6px;
    padding-top: 45px;
    margin-top: 8px;
    overflow: hidden;
    background-color: rgb(15, 15, 16);
  }

  .terminal::before {
    content: "\2022 \2022 \2022";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background: rgb(58, 58, 58);
    color: #c2c3c4;
    width: 100%;
    font-size: 2rem;
    line-height: 0;
    padding: 14px 0;
    text-indent: 4px;
  }

  .terminal pre {
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
    color: white;
    padding: 0 1rem 1rem;
    margin: 0;
  }

  .circle-link {
    height: 40px;
    width: 40px;
    border-radius: 40px;
    margin: 8px;
    background-color: white;
    border: 1px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: 1s ease-out;
  }

  .circle-link:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }

  footer {
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    line-height: 20px;
    height: 4%;
  }

  footer a {
    display: flex;
    align-items: center;
  }

  .github-star-badge {
    color: #24292e;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 3px 10px;
    border: 1px solid rgba(27,31,35,.2);
    border-radius: 3px;
    background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
    margin-left: 4px;
    font-weight: 600;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  }

  .github-star-badge:hover {
    background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
    border-color: rgba(27,31,35,.35);
    background-position: -.5em;
  }

  .github-star-badge .material-icons {
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }

  svg#clouds {
    position: fixed;
    bottom: -160px;
    left: -230px;
    z-index: -10;
    width: 1920px;
  }

  .hide{
      display: none;
      visibility: hidden;
  }

  /* Responsive Styles */
  @media screen and (max-width: 767px) {

    .card-container > *:not(.circle-link) ,
    .terminal {
      width: 100%;
    }

    .card:not(.highlight-card) {
      height: 16px;
      margin: 8px 0;
    }

    .card.highlight-card span {
      margin-left: 72px;
    }

    svg#rocket-smoke {
      right: 120px;
      transform: rotate(-5deg);
    }
  }

  @media screen and (max-width: 575px) {
    svg#rocket-smoke {
      display: none;
      visibility: hidden;
    }
  }

  .visualizer {
    width: 90%;
    height: 100%;
    border: 1px solid #fff;
    box-shadow: 2px rgba(0,0,0,1);
    border-radius: 2px;
}


[mat-dialog-title] {
  background-color: darkgray;
  color: #fff;
  cursor: move;
  height:8%  !important;
  text-align: center;
  margin: unset !important;
}

::ng-deep.mat-dialog-container {
  resize: both; 
  overflow: auto;
  background: black;
  padding: unset !important;
  border: 2px solid gray;
  display: flex;
    flex-direction: row;
    align-content: center;
}


::ng-deep.mat-dialog-content {
  padding: 0px !important;
  margin: 0px !important;
  overflow: auto;
  width: 100%  !important;
  height: 85%  !important;
  max-height: unset  !important;
}

::ng-deep.mat-dialog-actions{
  height:4% !important;
  margin-bottom: unset !important;
  min-height: unset  !important;
    background-color: grey; /* Fallback color */
}


.modal {
    position: relative;
    height:85%;
    width: 100%;
    width: unset; /* Full width */
	  padding-top:0px;
    overflow: auto; /* Enable scroll if needed */
    background-color: black; /* Fallback color */
    color: white;

}

/* Modal Content/Box */
.modal-content {
    background-color: black;
    padding: 0px;
    font-size: 12px;
    width: 100%;
    height: 100%;

}

button {
  width: 90px;
  height: 2%px;
  font-weight: 100;
}

.wideButton{
  width: 150px !important;
}

.buttons {
	grid-template-columns: auto auto ;
	display: grid;
	font-size: 14px;
	color: #fff;
	justify-content: center;
	margin-bottom: 10px;
	margin-right: 2px;
	width: 100%;
}

.close-button{
  float: right;
  top:-24px;
  right:-24px;
  width: auto;
}


.close-icon {
  transition: 1s ease-in-out;
}

.close-icon:hover {
  transform: rotate(180deg);
}

::ng-deep .icon-outside .close-button{
  float: right;
  top:-52px;
  right:-52px;
}

::ng-deep.icon-outside .mat-dialog-container {
 overflow: unset
}





/* The Close Button */
.close {
    display:block;
    width: 150px;
    color: black;    
    font-size: 16px;
    font-weight: bold;
    margin: auto;
}
    
.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}


